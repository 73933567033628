import AuthenticationProvider, {
  AuthenticationConfig,
  AutoLogin,
} from '@iad-os/react-ghost-auth';
import React, { useLayoutEffect, useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import './App.scss';
import ErrorManagement from './app/errors/ErrorManagement';
import InstallPWA from './components/core/InstallPWA';
import ProtectedRoutes from './components/routes/ProtectedRoutes';
import PublicRoutes from './components/routes/PublicRoutes';
import LoaderFullScreen from './components/shared/loader/loaderFullscreen/LoaderFullScreen';
import ModalApp from './components/shared/redux/modal/ModalApp';
import useIsIOS from './hooks/useIsIOS';
//import usePWA from './hooks/usePWA';
import { AuthorizationProps } from '@iad-os/react-ghost-auth/dist/Authentication';
import useWellKnown from './hooks/useWellKnown';
import BASE_PATHS from './routes/basePaths';
import { useRoute } from './utils/routes/useRoute';

function App() {
  const { wellKnown, loading } = useWellKnown();

  const { prompt } = useIsIOS();

  const { changeRoute } = useRoute();

  //const { installed } = usePWA();

  const authConfig = useMemo<AuthenticationConfig | undefined>(
    () =>
      wellKnown?.metadata
        ? {
            providers: [
              {
                ...wellKnown.metadata.issuer,
                issuer: wellKnown.metadata.issuer.name || 'default',
                name: wellKnown.metadata.issuer.name || 'default',
              },
            ],
          }
        : undefined,
    [wellKnown]
  );

  const handleOnRoute: AuthorizationProps['onRoute'] = route => {
    const url = new URL(route);
    changeRoute(url.pathname);
  };

  return (
    <>
      {!loading && authConfig && (
        <AuthenticationProvider
          overrideRedirectUri={lo => lo.href}
          config={authConfig}
          onRoute={handleOnRoute}
          saveOnLocalStorage
          enableLog
        >
          <Override />
          {prompt && <InstallPWA />}
          <LoaderFullScreen />
          <ErrorManagement />
          <ModalApp />
          <div about="switch">
            <Switch>
              <Route path={BASE_PATHS.PUBLIC}>
                <PublicRoutes />
              </Route>
              <Route path={BASE_PATHS.PROTECTED}>
                <ProtectedRoutes />
              </Route>
              <Redirect exact from={'/'} to={BASE_PATHS.PROTECTED} />
            </Switch>
          </div>
          <AutoLogin />
        </AuthenticationProvider>
      )}
    </>
  );
}

const Override = () => {
  const { wellKnown } = useWellKnown();

  useLayoutEffect(() => {
    if (wellKnown && wellKnown.metadata) {
      if (wellKnown.metadata.icon) {
        let link: any = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement('link');
          link.rel = 'icon';
          document.head.appendChild(link);
        }
        link.href = wellKnown.metadata.icon;
      }

      if (wellKnown.metadata.title) {
        document.title = wellKnown.metadata.title;
      }
    }
  }, [wellKnown]);
  return <></>;
};

export default App;
