import { RequireAuth } from '@iad-os/react-ghost-auth';
import React, { useEffect, useState } from 'react';
import { seti18n } from '../../config/i18n';
import MenuLayout from '../../features/auth/menu/MenuLayout';
import protectedRoutes from '../../routes/protected';
import ProtectedLayout from '../core/app-layout/components/ProtectedLayout';
import DynamicRoute from './DynamicRoute';
import AuthInterceptor from '../../app/auth/AuthInterceptor';

const ProtectedRoutes = () => {
  const [loadi18n, setLoadI18n] = useState(false);

  useEffect(() => {
    seti18n(setLoadI18n);
  }, []);

  const layouts = [
    {
      name: 'menuLayout',
      component: (data?: React.ReactNode) => <MenuLayout>{data}</MenuLayout>,
    },
  ];

  return (
    <RequireAuth authRequired={true}>
      {loadi18n && (
        <ProtectedLayout>
          <AuthInterceptor />
          <div className="auth">
            <DynamicRoute routes={protectedRoutes} layouts={layouts} />
          </div>
        </ProtectedLayout>
      )}
    </RequireAuth>
  );
};

export default ProtectedRoutes;
