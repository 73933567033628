import { TFunction } from 'i18next';
import React, { ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';
import RowTemplateDelete from '../../../features/auth/game/gamePlatform/components/RowTemplateDelete';
import { selectAllQuestionsForConditions } from '../../../features/auth/game/gamePlatform/gameNextOperationSlice';
import {
  IQuestion,
  ISkill,
} from '../../../features/auth/game/gamePlatform/models/gameQuestionConfiguration';
import {
  getAllAnswersByQuestionId,
  getAllQuestions,
} from '../../../features/auth/game/gamePlatform/services/gameConfiguration.service';
import { ICategory } from '../../../features/auth/models/Categories';
import {
  createAnswersOption,
  createCategoriesOption,
  createQuestionItemsOption,
} from '../../../utils/categoryUtils';
import FormElements from '../../../utils/forms/FormElements';
import { ValueName } from '../../../utils/forms/models/DataForm';
import { FormElementModelNew } from '../../../utils/forms/models/FormElementModel';
import { createStructureForm } from '../../../utils/forms/useStructureForm';
import { createSameOptions } from '../../../utils/select';
import BoxLabel from '../design/form/box/BoxLabel';

function useGameConditionForm(
  t: TFunction,
  dataFormatted: any,
  changeValue: ({ value, name }: ValueName) => void,
  dataForm: {
    [key: string]: any;
  },
  errors: {
    [key: string]: any;
  },
  isSubmit: boolean,
  categories: ICategory[],
  skills: ISkill[],
  langId: string
) {
  const questions = useSelector(selectAllQuestionsForConditions);
  const [state, setState] = useState<{
    [key in string]: any;
  }>({});

  function getSelectQuestionForm(
    formElement: any,
    argDataForm: any,
    {
      xs,
      label,
      name,
    }: {
      xs: number;
      label: string;
      name: string;
    }
  ) {
    const categoryName = (formElement.name as string).replace(
      'idQuestion',
      'categoryId'
    );
    const questionsName = (formElement.name as string).replace(
      'idQuestion',
      'questions'
    );

    const categoryId = argDataForm[categoryName];
    const actualCategoryId = state[categoryName];

    if (actualCategoryId !== `${categoryId}`) {
      actualCategoryId && changeValue({ value: '-1', name: formElement.name });
      setState(prev => ({
        ...prev,
        [categoryName]: `${categoryId}`,
      }));
      if (categoryId === -1) {
        setState(prev => ({
          ...prev,
          [questionsName]: [],
        }));
      } else {
        if (questions && questions.length > 0) {
          const filteredQuestions = questions.filter(
            item => item.categoryId === categoryId
          );
          setState(prev => ({
            ...prev,
            [questionsName]: filteredQuestions,
          }));
        } else {
          categoryId &&
            getAllQuestions(undefined, categoryId).then((res: IQuestion[]) =>
              setState(prev => ({
                ...prev,
                [questionsName]: res,
              }))
            );
        }
      }
    }

    return createStructureForm(
      [
        {
          typeElement: 'select',
          label,
          name,
          dataElement: {
            type: 'text',
          },
          options: [
            {
              value: '-1',
              label: t('APP.FORM.CHOOSE'),
            },
            ...createQuestionItemsOption(
              state[questionsName] ? state[questionsName] : [],
              langId
            ),
          ],
          col: { xs },
        },
      ],
      dataForm
    );
  }

  function getSelectAnswersConditionForm(
    formElement: any,
    argDataForm: any,
    {
      xs,
      label,
      name,
    }: {
      xs: number;
      label: string;
      name: string;
    }
  ) {
    const questionName = (formElement.name as string).replace(
      'answers',
      'idQuestion'
    );

    const answerName = `options_${formElement.name}`;

    const questionId = argDataForm[questionName];
    const actualQuestionId = state[questionName];

    if (actualQuestionId !== `${questionId}`) {
      actualQuestionId && changeValue({ value: '-1', name: formElement.name });

      setState(prev => ({
        ...prev,
        [questionName]: `${questionId}`,
      }));

      if (questionId && questionId !== '-1') {
        getAllAnswersByQuestionId(questionId, langId).then(res =>
          setState(prev => ({
            ...prev,
            [answerName]: res,
          }))
        );
      } else {
        setState(prev => ({
          ...prev,
          [answerName]: [],
        }));
      }
    }

    return createStructureForm(
      [
        {
          typeElement: 'select',
          label,
          name,
          multiply: true,
          value: state[formElement.name] ? state[formElement.name] : [],
          dataElement: {
            value: state[formElement.name] ? state[formElement.name] : [],
            type: 'text',
          },
          options: state[answerName]
            ? createAnswersOption(state[answerName])
            : [],
          col: { xs },
        },
      ],
      dataForm
    );
  }

  function getSelectSkillsForm(
    formElement: any,
    argDataForm: any,
    {
      xs,
      label,
      name,
    }: {
      xs: number;
      label: string;
      name: string;
    }
  ) {
    return createStructureForm(
      [
        {
          typeElement: 'creatableselect',
          label,
          name,
          options: createSameOptions(skills),
          col: { xs },
        },
      ],
      dataForm
    );
  }
  const conditions = {
    name: 'conditions',
    label: t('GAMEMANAGEMENT.CONDITIONS'),
    templateRow: (
      children: ReactNode,
      deleteElement?: () => void,
      values: any = {}
    ) => (
      <BoxLabel className="nested-accordion">
        <RowTemplateDelete
          deleteElement={() => deleteElement && deleteElement()}
        >
          {children}
        </RowTemplateDelete>
      </BoxLabel>
    ),
    form: [
      //Categories
      {
        typeElement: 'select',
        label: t('GAMEMANAGEMENT.CATEGORYID'),
        name: 'categoryId',
        dataElement: {
          type: 'text',
        },
        options: [
          {
            value: '-1',
            label: t('APP.FORM.CHOOSE'),
          },
          ...createCategoriesOption(categories),
        ],
      },
      //Questions
      {
        label: '',
        name: 'idQuestion',
        col: { xs: 12 },
        typeElement: 'select',
        template: (
          _children: ReactNode,
          formElement: any,
          templateDataForm: any
        ) => {
          return (
            <FormElements
              data={getSelectQuestionForm(formElement, templateDataForm, {
                label: t('GAMEMANAGEMENT.QUESTION'),
                name: formElement.name,
                xs: 12,
              })}
              dataFormatted={dataFormatted}
              errors={errors}
              isSubmit={isSubmit}
              dataForm={dataForm}
              changeValue={changeValue}
            />
          );
        },
      },
      //Answers
      {
        name: 'answers',
        col: { xs: 12 },
        typeElement: 'select',
        template: (
          _children: ReactNode,
          formElement: any,
          templateDataForm: any
        ) => {
          function onChangeHandler({ value, name }: ValueName) {
            changeValue({ value: value, name: name });
            setState(prev => ({
              ...prev,
              [name]: value,
            }));
          }

          return (
            <FormElements
              data={getSelectAnswersConditionForm(
                formElement,
                templateDataForm,
                {
                  label: t('GAMEMANAGEMENT.ANSWERS'),
                  name: formElement.name,
                  xs: 12,
                }
              )}
              dataFormatted={dataFormatted}
              errors={errors}
              isSubmit={isSubmit}
              dataForm={dataForm}
              changeValue={onChangeHandler}
            />
          );
        },
      },
      //skills
      {
        name: 'skills',
        label: t('GAMEMANAGEMENT.SKILLS'),
        templateRow: (
          children: ReactNode,
          deleteElement?: () => void,
          values: any = {}
        ) => (
          <BoxLabel className="nested-accordion">
            <RowTemplateDelete
              deleteElement={() => deleteElement && deleteElement()}
            >
              {children}
            </RowTemplateDelete>
          </BoxLabel>
        ),
        form: [
          {
            typeElement: 'creatableselect',
            name: 'name',
            col: { xs: 12 },
            template: (
              _children: ReactNode,
              formElement: any,
              templateDataForm: any
            ) => {
              function onChangeHandler({ value, name }: ValueName) {
                changeValue({ value: value.value, name: name });
              }

              return (
                <FormElements
                  data={getSelectSkillsForm(formElement, templateDataForm, {
                    label: t('GAMEMANAGEMENT.SKILLNAME'),
                    name: formElement.name,
                    xs: 12,
                  })}
                  dataFormatted={dataFormatted}
                  errors={errors}
                  isSubmit={isSubmit}
                  dataForm={dataForm}
                  changeValue={onChangeHandler}
                />
              );
            },
          },
          {
            typeElement: 'input',
            label: t('GAMEMANAGEMENT.SKILLPOINTFROM'),
            name: 'pointFrom',
            dataElement: {
              placeholder: t('GAMEMANAGEMENT.SKILLPOINTFROM'),
              type: 'number',
            },
            col: { xs: 6 },
          },
          {
            typeElement: 'input',
            label: t('GAMEMANAGEMENT.SKILLPOINTTO'),
            name: 'pointTo',
            dataElement: {
              placeholder: t('GAMEMANAGEMENT.SKILLPOINTTO'),
              type: 'number',
            },
            col: { xs: 6 },
          },
        ],
      },
    ],
  };

  const gameConditionStructure: FormElementModelNew[] = createStructureForm(
    [conditions],
    dataForm
  );
  return { conditions, gameConditionStructure };
}

export default useGameConditionForm;
