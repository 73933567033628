import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../../../app/store';
import {
  resetModal,
  setModal,
} from '../../../../../components/shared/redux/modal/modalSlice';
import i18n from '../../../../../config/i18n';
import http from '../../../../../utils/http';
import { processFile } from '../../../../../utils/readerFile';
import {
  addErrorMessage,
  addSuccessMessage,
} from './../../../../../app/errors/errorSlice';
import {
  deleteFile,
  getFiles,
  uploadFile,
} from './../../../services/upload.service';

export interface IUserManagementSlice {
  langData: any[];
  dropdownSelectableLangs: any[];
}
const initialState: IUserManagementSlice = {
  langData: [],
  dropdownSelectableLangs: [],
};

export const LanguagesManagementSlice = createSlice({
  name: 'LanguagesManagement',
  initialState,
  reducers: {
    setLangData: (state, action: PayloadAction<any[]>) => {
      state.langData = action.payload;
    },
    setDropdownSelectableLangs: (state, action: PayloadAction<any[]>) => {
      state.dropdownSelectableLangs = action.payload;
    },
  },
});

export const { setLangData, setDropdownSelectableLangs } =
  LanguagesManagementSlice.actions;

export const saveResource =
  (resource: any): AppThunk =>
  dispatch => {
    let formData = new FormData();
    const file = new Blob([JSON.stringify(resource.data)], {
      type: 'application/json',
    });
    const nameFile = resource.i18n;

    formData.append(nameFile, file);
    uploadFile(formData, {
      isPublic: 'true',
      isStaticName: 'true',
      pathName: 'i18n',
      filename: nameFile,
    }).then(
      (result: any) => {
        dispatch(
          addSuccessMessage({
            title: i18n.t('SUCCESSMSG.LOCALE'),
          })
        );
        dispatch(getResources());
      },
      err => {
        dispatch(
          addErrorMessage({
            title: i18n.t('ERRORSMSG.LOCALE'),
          })
        );
      }
    );
  };
export const uploadResource =
  (file: any): AppThunk =>
  dispatch => {
    processFile(file).then((result: any) => {
      let formData = new FormData();
      const nameFile = file.name.split('.')[0];
      formData.append(nameFile, file);
      uploadFile(formData, {
        isPublic: 'true',
        isStaticName: 'true',
        pathName: 'i18n',
        filename: nameFile,
      }).then(
        uploadRes => {
          dispatch(
            addSuccessMessage({
              title: i18n.t('SUCCESSMSG.LOCALE'),
            })
          );
          dispatch(getResources());
        },
        err => {
          dispatch(
            addErrorMessage({
              title: i18n.t('ERRORSMSG.LOCALE'),
            })
          );
        }
      );
    });
  };
export const deleteResource =
  (pathFileName: string): AppThunk =>
  dispatch => {
    const buttonList = [
      {
        label: i18n.t('MODAL.CANCELL'),
        action: 'cancelDelete',
        type: 'secondary',
        dataType: {},
      },
      {
        label: i18n.t('MODAL.CONFIRM'),
        action: 'confirmDelete',
        type: 'primary',
        dataType: {},
      },
    ];
    const confirmDelete = () => {
      deleteFile(pathFileName).then(
        (res: any) => {
          dispatch(
            addSuccessMessage({
              title: i18n.t('SUCCESSMSG.DELETELOCALE'),
            })
          );
          dispatch(getResources());
        },
        (err: any) =>
          dispatch(
            addErrorMessage({
              title: i18n.t('ERRORSMSG.DELETELOCALE'),
            })
          )
      );
      dispatch(resetModal());
    };
    const cancelDelete = () => {
      dispatch(resetModal());
    };
    dispatch(
      setModal(
        buttonList,
        {
          title: i18n.t('MESSAGES.DELETELOCALE'),
          body: i18n.t('MESSAGES.CONFIRMDELETELOCALE ' + pathFileName),
        },
        {
          cancelDelete,
          confirmDelete,
        }
      )
    );
  };

export const getResources = (): AppThunk => dispatch => {
  function promise(url: string) {
    const i18nName = url.split('/').pop()?.split('.')[0] || 'err';
    return http.get(url).then(data => {
      return {
        i18n: i18nName,
        data,
      };
    });
  }
  getFiles({ pathName: 'i18n' }).then(res => {
    const calls: Promise<any>[] = [];
    res.forEach(({ cdn }) => {
      calls.push(promise(cdn));
    });

    Promise.all(calls).then(response => {
      dispatch(setLangData(response));
    });
  });
};

export const selectLangData = (state: RootState) =>
  state.LanguagesManagement.langData;

export const selectDropdownSelectableLangs = (state: RootState) =>
  state.LanguagesManagement.dropdownSelectableLangs;

export default LanguagesManagementSlice.reducer;
