import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './app/store';
import ScreenOrientation from './components/core/ScreenOrientation';
import { routesList } from './components/routes/calcRoutes';
import {
  decrementN,
  incrementN,
} from './components/shared/loader/redux/loadSlice';
import './index.scss';
import * as serviceWorkerRegistration from './serviceWorker';
import clearRequestInterceptor from './utils/interceptors/clearRequestInterceptor';
import { routes } from './utils/routes/getRoutes';
import 'react-datetime/css/react-datetime.css';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ScreenOrientation>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ScreenOrientation>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

routes.routes = routesList;

axios.interceptors.request.use(config => {
  store.dispatch(incrementN());
  return config;
});
axios.interceptors.response.use(
  (config: any) => {
    store.dispatch(decrementN());
    return config;
  },
  err => {
    store.dispatch(decrementN());
    return Promise.reject(err);
  }
);

axios.interceptors.request.use(clearRequestInterceptor);
// axios.interceptors.request.use(authInterceptor);
// axios.interceptors.response.use((config: any) => config, logInterceptor);
// axios.interceptors.response.use((config: any) => config, status401Interceptor);
// axios.interceptors.response.use((config: any) => config, errorInterceptor);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
