import { omit } from 'lodash';
import http from '../../../../../utils/http';
import { IQuestion } from '../models/gameQuestionConfiguration';
import {
  IGameConfig,
  IMicroGameConfig,
  IParamGame,
  IParamQuestion,
} from './../models/gameConfiguration';
import { IResultConfig } from './../models/gameResultConfig';

const urlGameConfig = `/game-configuration`;
const urlQuestionConfig = `/question-configuration`;
const singleQuestion = `/question`;

export const getAllGameConfig = (): Promise<IGameConfig[]> =>
  http.get(`${urlGameConfig}/game`);

export const getGameConfig = (catId: string): Promise<IGameConfig> =>
  http.get(`${urlGameConfig}/game/${catId}`);

export const saveGameConfig = (
  catId: string,
  data: IGameConfig
): Promise<IGameConfig> => {
  const newData = omit(data, ['id', 'categoryId', '_id']);
  return http.post(`${urlGameConfig}/game/${catId}`, newData);
};
export const modifyGameConfig = (
  catId: string,
  data: IGameConfig
): Promise<IGameConfig> => {
  const newData = omit(data, ['id', 'categoryId', '_id', '_class']);
  return http.put(
    `${urlGameConfig}/game/${catId}/${data._id.version}`,
    newData
  );
};

export const deleteGameConfig = (data: IGameConfig): Promise<any> => {
  return http.delete(
    `${urlGameConfig}/game/${data.categoryId}/${data._id.version}`
  );
};

export const getGameResult = (catId: string): Promise<IResultConfig> =>
  http.get(`${urlGameConfig}/result/${catId}`);

export const saveGameResult = (
  catId: string,
  data: IResultConfig
): Promise<IResultConfig> => {
  const newData = omit(data, ['categoryId']);
  return http.post(`${urlGameConfig}/result/${catId}`, newData);
};
export const editGameResult = (
  catId: string,
  data: IResultConfig
): Promise<IResultConfig> => {
  const newData = omit(data, ['id', '_id', '_class', 'categoryId']);
  return http.put(`${urlGameConfig}/result/${catId}`, newData);
};

export const deleteGameResult = (catId: number): Promise<any> => {
  return http.delete(`${urlGameConfig}/result/${catId}`);
};

export const getParamGameConfig = (): Promise<IParamGame> =>
  http.get(`${urlGameConfig}/param`);
export const getParamQuestionConfig = (): Promise<IParamQuestion> =>
  http.get(`${urlQuestionConfig}/param`);

export const getQuestionConfigurationByGameId = (
  gameId: string
): Promise<IQuestion[]> =>
  http.get(`${urlQuestionConfig}${singleQuestion}`, { params: { gameId } });

export const upsertQuestion = (data: IQuestion): Promise<any> => {
  const newData = omit(data, [
    'id',
    '_id',
    '_class',
    'gameId',
    'categoryId',
    'subCategoryId',
  ]);
  if (!!data._id && data._id !== '') {
    return http.put(
      `${urlQuestionConfig}${singleQuestion}/${data.categoryId}/${data.subCategoryId}/${data._id}`,
      newData
    );
  } else {
    return http.post(
      `${urlQuestionConfig}${singleQuestion}/${data.categoryId}/${data.subCategoryId}`,
      newData
    );
  }
};
export const deleteQuestion = (data: IQuestion): Promise<any> =>
  http.delete(
    `${urlQuestionConfig}${singleQuestion}/${data.categoryId}/${data.subCategoryId}/${data._id}`
  );

export const getMicrogameConfig = (): Promise<IMicroGameConfig[]> =>
  http.get(`${urlGameConfig}/microgame`);

export const getMicrogameConfigbyCatId = (
  catId?: string
): Promise<IMicroGameConfig[]> =>
  http.get(`${urlGameConfig}/microgame/${catId}`);

export const saveMicrogameConfig = (
  catId: string,
  data: IMicroGameConfig
): Promise<IMicroGameConfig> => {
  const newData = omit(data, ['id', '_id', 'areaCategoryId']);
  return http.post(`${urlGameConfig}/microgame/${catId}`, newData);
};
export const editMicrogameConfig = (
  catId: string,
  data: IMicroGameConfig
): Promise<IMicroGameConfig> => {
  const newData = omit(data, ['id', '_id', '_class', 'areaCategoryId']);
  return http.put(`${urlGameConfig}/microgame/${catId}/${data._id}`, newData);
};
export const deleteMicrogameConfig = (data: IMicroGameConfig): Promise<any> =>
  http.delete(`${urlGameConfig}/microgame/${data.areaCategoryId}/${data._id}`);

const setQueryString = (
  gameId?: string,
  categoryId?: number,
  subCategoryId?: number
) => {
  const gameIdStr = gameId ? `gameId=${gameId}` : undefined;
  const catIdStr = categoryId ? `categoryId=${categoryId}` : undefined;
  const subCatIdStr = subCategoryId
    ? `subCategoryId=${subCategoryId}`
    : undefined;

  return `${gameIdStr ? `${gameIdStr}&` : ''}${catIdStr ? `${catIdStr}&` : ''}${
    subCatIdStr ? subCatIdStr : ''
  }`;
};

export const getAllSkills = (
  languageId: string,
  gameId?: string,
  categoryId?: number,
  subCategoryId?: number
) => {
  const queryString = setQueryString(gameId, categoryId, subCategoryId);
  return http.get(
    `${urlQuestionConfig}${singleQuestion}/skills?languageId=${languageId}&${queryString}`
  );
};

export const getAllQuestions = (
  gameId?: string,
  categoryId?: number,
  subCategoryId?: number
) => {
  const queryString = setQueryString(gameId, categoryId, subCategoryId);
  return http.get(`${urlQuestionConfig}${singleQuestion}?${queryString}`);
};
export const getAllAnswersByQuestionId = (
  questionId: string,
  languageId: string
) => {
  return http.get(
    `${urlQuestionConfig}${singleQuestion}/answers/${questionId}?languageId=${languageId}`
  );
};

export const getAllAnswersByGameId = (gameId: string) => {
  return http.get(
    `${urlQuestionConfig}${singleQuestion}/answers?gameId=${gameId}`
  );
};
