import isEmpty from 'lodash.isempty';
import omit from 'lodash.omit';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  getListCategories,
  selectCategories,
} from '../../../app/categoriesSlice';
import { addErrorMessage } from '../../../app/errors/errorSlice';
import AnimationFrame from '../../../components/shared/animation-frame/AnimationFrame';
import defaultDataToolbarBtn from '../../../components/shared/custom/sidebar/defaultDataToolbarBtn/DefaultDataToolbarBtn';
import ToolbarButton from '../../../components/shared/custom/toolbar/toolbarButton/ToolbarButton';
import { GameHeader } from '../../../components/shared/game-header/GameHeader';
import Icon from '../../../components/shared/icon/Icon';
import i18n from '../../../config/i18nPublic';
import { EvaluationType } from '../../../hooks/useEvalutation';
import useStateHistory from '../../../hooks/useStateHistory';
import { useRoute } from '../../../utils/routes/useRoute';
import { getEvaluations } from '../actions/service/action.service';
import EditModeBtn from '../common/EditModeBtn';
import ContentPageLayoutV2 from '../ContentPageLayoutV2';
import useBackgroundForm from './adminSidebar/useBackgroundForm';
import SidebarContent from './components/SidebarContent';
import {
  AnimateData,
  AnimationType,
  GameHeaderType,
  ICustomBackground,
} from './models/types';
import {
  deleteAnimate,
  getAnimate,
  getAnimates,
  getAnimateToPlay,
  postAnimate,
  putAnimate,
} from './services/animation.service';

export function fixGameHeaderDataFormReload(dataForm: any) {
  //valori iniziali
  const gameHeader: GameHeaderType = dataForm['gameHeader'];
  if (gameHeader) {
    const skills: string[] | string = gameHeader.Skills;
    const skillsStringify = JSON.stringify(skills);
    gameHeader.Skills = skillsStringify;
    dataForm['gameHeader'] = [gameHeader];
  }

  return dataForm;
}

export function fixGameHeaderDataFormSubmit(data: any) {
  //ho messo any perchè la utilizzo anche per GameSettings
  if (data.gameHeader) {
    const array: any = data.gameHeader;
    const gameHeader: GameHeaderType = {
      gameFatherId: array[0].gameFatherId,
      Skills: JSON.parse(array[0].Skills),
    };
    const fixed: any = { ...data, gameHeader: gameHeader };
    return fixed;
  } else {
    return data;
  }
}

function AnimationLayout() {
  const { id } = useParams<{ id: string }>();
  const { goBack } = useRoute();
  const dispatch = useDispatch();

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isBtnClicked, setIsBtnClicked] = useState(false);
  const [isSubmitForm, setIsSubmitForm] = useState(false);

  const [saved, setSaved] = useState(false);
  const [skillScoreChange, setSkillScoreChange] = useState<boolean>(false);

  const [evalutations, setEvalutations] = useState<EvaluationType[]>();

  const [
    currentAnimateConfig,
    setCurrentAnimateConfig,
    { setIndex: setNewIndex, undo, redo, index, history, resetWith },
  ] = useStateHistory<AnimateData>();

  const [currentAnimateData, setCurrentAnimateData] = useState<AnimateData>();
  const [playAnimateData, setPlayAnimateData] = useState<AnimateData>();
  const [currentBg, setCurrentBg] = useState<ICustomBackground>();
  const categories = useSelector(selectCategories);

  const { btnListRight, btnListRightCheck, btnListLeftCheck, btnListRightBg } =
    defaultDataToolbarBtn(index, history);

  const funcAnimationToolbarBtn: { [key: string]: (data?: any) => void } = {
    delete: () => {
      deleteAnimateById();
    },
    save: () => {
      setSaved(false);
      currentAnimateData && currentAnimateData._id
        ? updateAnimate()
        : createAnimate();
    },
    undo: () => {
      undo();
      setIsBtnClicked(true);
    },
    redo: () => {
      redo();
      setIsBtnClicked(true);
    },
    history: () => {
      setNewIndex(0);
      setIsBtnClicked(true);
    },
    play: () => {
      currentAnimateData && setPlayAnimateData(currentAnimateData);
    },
    stop: () => {
      handlePlay();
    },
  };

  const funcCheckToolbarBtn: { [key: string]: (data?: any) => void } = {
    save: () => {
      setIsSubmitForm(true);
    },
  };

  const { displayThumbs, selectedBg } = useBackgroundForm(
    id,
    {
      left: [],
      right: btnListRightBg,
    },
    'animates',
    'animate-bg'
  );

  useEffect(() => {
    if (categories.length === 0) {
      dispatch(getListCategories());
    }
    getEvaluations(id).then(setEvalutations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (saved) {
      setIsEditMode(false);
      reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saved]);

  useEffect(() => {
    reload(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    setCurrentBg(selectedBg);
  }, [selectedBg]);

  async function reload(save?: boolean) {
    try {
      const current = await getAnimate(id);
      setCurrentAnimateData(fixGameHeaderDataFormReload(current));
      if (current._id) {
        const configData = await getAnimates(id, current._id);
        save !== undefined && setSaved(save);
        resetWith(fixGameHeaderDataFormReload(configData));
      }
      setSkillScoreChange(true);
    } catch (err) {
      resetWith({
        animates: [],
      });
      setCurrentAnimateData({
        animates: [],
      });
      setSkillScoreChange(false);
    } finally {
      //salvo nel dispatch gli animates aggiornati perchè mi servono per la configurazione domande
      //altrimenti nelle select non carica i nuovi inserimenti
      // dispatch(getAnimationGames());
    }
  }

  function deleteAnimateById() {
    if (currentAnimateData && currentAnimateData._id) {
      deleteAnimate(currentAnimateData._id, id).then(
        res => {
          setCurrentAnimateData({ animates: [] });
          setCurrentAnimateConfig({ animates: [] });
          //salvo nel dispatch gli animates aggiornati perchè mi servono per la configurazione domande
          //altrimenti nelle select non carica i nuovi inserimenti
          // dispatch(getAnimationGames());
        },
        err => {
          dispatch(
            addErrorMessage({
              title: i18n.t('ERRORSMSG.DELETINGANIMATEFAILED'),
            })
          );
        }
      );
    }
  }

  function createAnimate() {
    if (currentAnimateConfig) {
      const copy = Object.assign(
        {},
        fixGameHeaderDataFormSubmit(currentAnimateConfig)
      );
      copy.animates = copy?.animates.map((i: AnimateData) =>
        omit(i, ['_id'])
      ) as AnimationType[];
      postAnimate(id, {
        ...omit(setProgressive(copy), ['_id', 'animateId']),
      }).then(
        res => {
          setSaved(true);
        },
        err => {
          dispatch(
            addErrorMessage({
              title: i18n.t('ERRORSMSG.SAVINGANIMATEFAILED'),
            })
          );
        }
      );
    }
  }

  function updateAnimate() {
    if (currentAnimateData && currentAnimateData._id && currentAnimateConfig) {
      const currentAnimateConfigFixed =
        fixGameHeaderDataFormSubmit(currentAnimateConfig);
      const animates = currentAnimateConfigFixed?.animates.map(
        (i: AnimateData) => omit(i, ['_id'])
      ) as AnimationType[];
      putAnimate(
        id,
        currentAnimateData._id,
        setProgressive({
          ...omit(currentAnimateConfigFixed, ['_id']),
          animates,
        })
      ).then(
        res => {
          setSaved(true);
        },
        err => {
          dispatch(
            addErrorMessage({
              title: i18n.t('ERRORSMSG.UPDATINGANIMATEFAILED'),
            })
          );
        }
      );
    }
  }

  function setProgressive(animate: AnimateData): AnimateData {
    return {
      ...animate,
      animates: animate.animates.map((v, i) => ({ ...v, progressive: i + 1 })),
    };
  }

  function handlerAnimationActionToolbarBtn<T>(action: string, data?: T) {
    funcAnimationToolbarBtn &&
      funcAnimationToolbarBtn[action] &&
      funcAnimationToolbarBtn[action](data);
  }

  function handlerCheckActionToolbarBtn<T>(action: string, data?: T) {
    funcCheckToolbarBtn &&
      funcCheckToolbarBtn[action] &&
      funcCheckToolbarBtn[action](data);
  }

  function handleDataFormChange(data: AnimateData) {
    setCurrentAnimateConfig({ ...omit(data, ['_id']) });
  }

  function topToolbarRightTemplate() {
    return (
      <div>
        <EditModeBtn handlerAction={() => setIsEditMode(prev => !prev)} />
      </div>
    );
  }

  function bottomToolbarRightTemplate() {
    return (
      <div>
        <ToolbarButton clickAction={goBack} icon="exit_to_app" />
      </div>
    );
  }

  function gameHeaderTemplate() {
    return (
      <div>
        {currentAnimateData && currentAnimateData.gameHeader && (
          <GameHeader
            isSkillScoreChanged={skillScoreChange}
            categoryId={currentAnimateData.gameHeader[0].gameFatherId}
            backgroundcolor={'rgba(255, 255, 255, 0.5)'}
          />
        )}
      </div>
    );
  }

  function sidebarTemplate() {
    return (
      currentAnimateConfig && (
        <SidebarContent
          isEditMode={isEditMode}
          btnListLeft={[]}
          btnListRight={btnListRight}
          btnListLeftCheck={btnListLeftCheck}
          btnListRightCheck={btnListRightCheck}
          animationActionToolbarBtn={handlerAnimationActionToolbarBtn}
          checkActionToolbarBtn={handlerCheckActionToolbarBtn}
          animateData={currentAnimateConfig}
          isBtnClicked={isBtnClicked}
          isSubmitForm={isSubmitForm}
          setIsSubmitCheckForm={setIsSubmitForm}
          handleDataFormChange={handleDataFormChange}
          categories={categories}
          tabBackground={displayThumbs}
        />
      )
    );
  }

  function handlePlay() {
    getAnimateToPlay(id).then(setPlayAnimateData);
  }

  return (
    <>
      <ContentPageLayoutV2
        fullScreen
        toolbarTopTemplates={{
          center: gameHeaderTemplate(),
        }}
        toolbarRightTemplates={{
          top: topToolbarRightTemplate(),
          //center: gameHeaderTemplate(),
          bottom: bottomToolbarRightTemplate(),
        }}
        contentLayout={
          <>
            {!isEmpty(playAnimateData?.animates) ? (
              <AnimationFrame
                title={'animation'}
                src={`${playAnimateData?.animates[0].uri}` || ''}
                evalutations={evalutations}
              />
            ) : (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{
                  height: '100vh',
                  backgroundImage: `url(${
                    currentBg ? currentBg.image.iconUrl : ''
                  })`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
              >
                <Row className="justify-content-md-center">
                  <Col className="mt-3 p-3 container-play" md="auto">
                    <Icon
                      icon={'play_circle_outline'}
                      colorStyle={'primary'}
                      size="300px"
                      disabled={false}
                      title={'Play'}
                      clickAction={handlePlay}
                    ></Icon>
                  </Col>
                </Row>
              </div>
            )}
          </>
        }
        sidebarContentTemplate={sidebarTemplate()}
        isEditMode={isEditMode}
      />
    </>
  );
}

export default AnimationLayout;
