import axios from 'axios';
import isEmpty from 'lodash.isempty';
import { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';

export type WellKnown = {
  domain: string;
  metadata: Metadata;
};

type Issuer = {
  name: string | undefined;
  authorization_endpoint: string;
  token_endpoint: string;
  client_id: string;
  requested_scopes: string;
  redirect_uri: string;
  end_session_endpoint: string;
  redirect_logout_uri: string;
  realm: string;
  pkce: boolean | undefined;
  kc_idp_hint: string | undefined;
};

type Metadata = {
  name: string;
  endpoint_api: string;
  issuer: Issuer;
  active_debug: boolean;
  playback_url: string;
  title: string | undefined;
  logo: Logo | undefined;
  icon: string | undefined;
};

type Logo = {
  url: string | undefined;
  width: string | undefined;
  height: string | undefined;
};

const URL =
  /^(?:([a-z0-9+.-]+):\/\/)(?:\S+(?::\S*)?@)?(?:(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
const urlYup = yup.string().matches(URL, 'Enter a valid url');
const wellknownSchema = yup
  .object()
  .shape<{ [key in keyof WellKnown]: yup.AnySchema }>({
    domain: urlYup.required(),
    metadata: yup
      .object()
      .shape<{ [key in keyof Metadata]: yup.AnySchema }>({
        name: yup.string().required(),
        active_debug: yup.boolean(),
        endpoint_api: urlYup.required(),
        playback_url: urlYup.required(),
        title: yup.string(),
        logo: yup.object().shape<{ [key in keyof Logo]: yup.AnySchema }>({
          height: yup.string(),
          url: urlYup,
          width: yup.string(),
        }),
        icon: urlYup,
        issuer: yup
          .object()
          .shape<{ [key in keyof Issuer]: yup.AnySchema }>({
            name: yup.string().required(),
            authorization_endpoint: urlYup.required(),
            token_endpoint: urlYup.required(),
            client_id: yup.string().required(),
            requested_scopes: yup.string().required(),
            realm: yup.string().required(),
            redirect_uri: urlYup.required(),
            end_session_endpoint: urlYup.required(),
            redirect_logout_uri: urlYup.required(),
            kc_idp_hint: yup.string(),
            pkce: yup.boolean().default(true),
          })
          .required(),
      })
      .required(),
  });

const useWellKnown = () => {
  const [wellKnown, setWellKnown] = useState<WellKnown>();

  const loading = isEmpty(WellKnownProfile.getData());

  const url = useMemo(() => {
    if (process.env.REACT_APP_WELL_KNOWN) {
      return process.env.REACT_APP_WELL_KNOWN;
    } else {
      const { hostname } = window.location;
      const lvl = hostname.split('.');
      if (lvl.length === 2) {
        return `https://api.${hostname}/.well-known/app-configuration`;
      } else if (lvl.length === 3) {
        const [sub, domain, region] = lvl;
        return `https://${sub}-api.${domain}.${region}/.well-known/app-configuration`;
      }
      throw new Error(
        'Abbiamo un problema di struttura dominio. Max 3 livelli es: sub.domain.com'
      );
    }
  }, []);

  async function createWellKnown(data: WellKnown) {
    try {
      //data.metadata.issuer.redirect_uri = 'http://localhost:3000/protected';
      const res = await wellknownSchema.validate(data);
      WellKnownProfile.setData(res);
      setWellKnown(() => res);
    } catch (err) {
      throw err;
    }
  }

  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.create().get<WellKnown>(url);
        createWellKnown(data);
      } catch (err) {
        console.error('Well known', err);
      }
    }
    if (!WellKnownProfile.getData()) {
      fetch();
    } else {
      setWellKnown(WellKnownProfile.getData());
    }
  }, [url]);

  return { wellKnown, loading };
};

export const WellKnownProfile = (function () {
  let data: WellKnown;

  let url: string;

  const getData = function () {
    return data;
  };

  const getEnpointApi = function () {
    return data?.metadata?.endpoint_api;
  };

  const getActiveDebug = function () {
    return data?.metadata?.active_debug;
  };

  const setData = function (input: WellKnown) {
    data = input;
  };

  const getUrl = function () {
    return url;
  };

  const setUrl = function (input: string) {
    url = input;
  };

  return {
    getData,
    setData,
    getEnpointApi,
    getActiveDebug,
    getUrl,
    setUrl,
  };
})();

export default useWellKnown;
