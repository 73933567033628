import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';
import React, { useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectCategories } from '../../../app/categoriesSlice';
import BoxLabel from '../../../components/shared/design/form/box/BoxLabel';
import Icon from '../../../components/shared/icon/Icon';
import FormElements from '../../../utils/forms/FormElements';
import { ValueName } from '../../../utils/forms/models/DataForm';
import { UseFormSubmitModel } from '../../../utils/forms/models/UseFormModel';
import { useForm } from '../../../utils/forms/useForm';
import {
  getHttpCodeList,
  selectHttpCodeList,
} from '../menu/menuLevels/aclManagement/AclManagementSlice';
import { HttpCodeItem } from '../menu/menuLevels/aclManagement/model/config';
import { ICheckItem } from '../menu/menuLevels/checkManagement/model/config';
import { setIsSumbitForm } from '../projectMap/mapSlice';
import {
  addArgumentInList,
  addCategoryInList,
  createNewCheck,
  getCheck,
  getNamesList,
  removeArgumentFromList,
  removeCategoryFromList,
  resetListArguments,
  resetListCategories,
  selectListArguments,
  selectListCategories,
  selectListNames,
  selectSelectedCheck,
  setCheck,
  setSelectedCheck,
  updateActionCheck,
} from './CheckSlice';
import { useCheckForm } from './useCheckForm';
import { validations } from './ValidationData';

const Check = (props: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selected, submitForm, options } = props;
  const httpCodeList = useSelector(selectHttpCodeList);
  const parametersList = useSelector(selectListArguments);
  const namesList = useSelector(selectListNames);
  const categoriesList = useSelector(selectCategories);
  const selectedCategoriesList = useSelector(selectListCategories);
  const selectedCheck = useSelector(selectSelectedCheck);

  const { checkNameRow, checkArgsRow, checkCategoryRow, checkHttpRow } =
    useCheckForm(t, httpCodeList, namesList, categoriesList);

  const afterSubmit = () => {
    //setData([]);
    resetForm([]);
    setIsSumbitForm(false);
  };

  const submitHandler: UseFormSubmitModel = (event, data) => {
    let newDataForm = { ...data };
    let categories = selectedCategoriesList.map(selectedCategory => {
      return Number(selectedCategory.split('-')[1]);
    });
    newDataForm.categories = categories;
    newDataForm.paramenter = parametersList;
    if (!selected?.acl) {
      dispatch(createNewCheck(newDataForm as ICheckItem, afterSubmit));
    } else {
      dispatch(updateActionCheck(newDataForm as ICheckItem, afterSubmit));
    }
  };

  const validationsData = validations();

  const { errors, submitAction, changeValue, dataForm, setData, resetForm } =
    useForm(submitHandler, validationsData);

  const changeValueEnd = ({ value, name }: ValueName) => {
    changeValue({ value: value, name: name });
    if (name === 'httpStatusRespone') {
      let httpCodeToSet = httpCodeList.filter((httpCode: HttpCodeItem) =>
        isEqual(`${httpCode.code}`, value)
      );
      setData({
        ...dataForm,
        httpStatusRespone: !isEmpty(httpCodeToSet) ? httpCodeToSet[0].code : '',
        errorMessage: !isEmpty(httpCodeToSet)
          ? httpCodeToSet[0].description
          : '',
      });
    }
    if (name === 'acl') {
      value.length > 2 && dispatch(getNamesList(value));
    }
    dispatch(setSelectedCheck({ ...dataForm, [name]: value } as ICheckItem));
  };

  const handleArgsGenerator = () => {
    dataForm.paramenter && dispatch(addArgumentInList(dataForm.paramenter));
  };

  const handleCategoryGenerator = () => {
    dataForm.categories && dispatch(addCategoryInList(dataForm.categories));
  };

  const handleClickIcon = (data: any, type: string) => {
    if (type === 'parameter') {
      dispatch(removeArgumentFromList(data));
    }
    if (type === 'category') {
      dispatch(removeCategoryFromList(data));
    }
  };

  useEffect(() => {
    httpCodeList.length === 0 && dispatch(getHttpCodeList());
    dispatch(resetListCategories());
    dispatch(resetListArguments());
    if (selected) {
      dispatch(getCheck(selected.acl));
    }
    selectedCheck && dispatch(setCheck(selectedCheck));
    selectedCheck && setData(selectedCheck);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    submitForm && submitAction && submitAction();

    options && options.setIsSubmitCheckForm
      ? options.setIsSubmitCheckForm(false)
      : dispatch(setIsSumbitForm(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitForm]);

  return (
    <>
      <Form onSubmit={submitAction}>
        <Row className="align-items-center">
          <Col xs="12">
            <FormElements
              data={checkNameRow}
              errors={errors}
              isSubmit={true}
              dataForm={dataForm}
              changeValue={changeValueEnd}
            />
          </Col>
          <Col xs="12">
            <FormElements
              data={checkArgsRow}
              errors={errors}
              isSubmit={true}
              dataForm={dataForm}
              changeValue={changeValueEnd}
            />
            <Button onClick={handleArgsGenerator}>+</Button>
          </Col>
        </Row>
        {parametersList?.length > 0 && (
          <BoxLabel label={t('CHECK.PARAMETERLIST')}>
            {parametersList.map((argument: any, key: number) => {
              return (
                <Row
                  className="align-items-center justify-content-between"
                  key={key}
                >
                  <Col xs="6">{argument}</Col>
                  <Icon
                    icon="delete"
                    className="pointer"
                    onClick={() => handleClickIcon(argument, 'parameter')}
                  ></Icon>
                </Row>
              );
            })}
          </BoxLabel>
        )}
        <Row className="align-items-center">
          <Col xs="12">
            <FormElements
              data={checkCategoryRow}
              errors={errors}
              isSubmit={true}
              dataForm={dataForm}
              changeValue={changeValueEnd}
            />
            <Button onClick={handleCategoryGenerator}>+</Button>
          </Col>
        </Row>
        {selectedCategoriesList.length > 0 && (
          <BoxLabel label={t('CHECK.SELECTEDCATEGORYLIST')}>
            {selectedCategoriesList.map((category: any, key: number) => {
              return (
                <Row
                  className="align-items-center justify-content-between"
                  key={key}
                >
                  <Col xs="6">{category.split('-')[0]}</Col>
                  <Icon
                    icon="delete"
                    className="pointer"
                    onClick={() => handleClickIcon(category, 'category')}
                  ></Icon>
                </Row>
              );
            })}
          </BoxLabel>
        )}
        <Row className="align-items-center">
          <Col xs="12">
            <FormElements
              data={checkHttpRow}
              errors={errors}
              isSubmit={true}
              dataForm={dataForm}
              changeValue={changeValueEnd}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Check;
