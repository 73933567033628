import isEqual from 'lodash.isequal';
import omit from 'lodash.omit';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectUser } from '../../../app/auth/authSlice';
import { IMe } from '../../../app/auth/model/User';
import {
  addErrorMessage,
  addSuccessMessage,
} from '../../../app/errors/errorSlice';
import Icon from '../../../components/shared/icon/Icon';
import { isDocOrVideoOrCourseOrResources } from '../../../utils/categoryUtils';
import { AnimateData } from '../animations/models/types';
import { getGameQuestionAnimates } from '../animations/services/animation.service';
import { ISkill } from '../game/gamePlatform/models/gameQuestionConfiguration';
import { getAllSkills } from '../game/gamePlatform/services/gameConfiguration.service';
import { getCategory } from '../menu/menuLevels/categoryManagement/services/category.service';
import { ICategory } from '../models/Categories';
import { IResource } from '../resources/model/layout';
import { getAllHyperlinks } from '../resources/services/resources.service';
import ActionForm from './components/ActionForm';
import { IAction, IParam, ITrigger, SubCategoryType } from './models/type';
import {
  deleteAction,
  postAction,
  putAction,
  retriveAllActions,
  retriveAllParams,
} from './service/action.service';

function Actions({ categories }: { categories: ICategory[] }) {
  const { t } = useTranslation();
  const user: IMe | undefined = useSelector(selectUser);
  const [actions, setActions] = useState<IAction[]>([]);
  const [skills, setSkills] = useState<ISkill[]>([]);
  const [params, setParams] = useState<IParam>({
    trigger: [],
    event: [],
    goTo: [],
  });
  const [animationGames, setAnimationGames] = useState<AnimateData[]>([]);
  const [depthCategories, setDepthCategories] = useState<ICategory>();
  const [resourcesData, setResourcesData] = useState<IResource[]>([]);
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  useEffect(() => {
    getGameQuestionAnimates()
      .then(res => {
        setAnimationGames(res);
      })
      .then(() => getActions());
    getParams();
    getAllSkills(
      user?.preference.language || 'it',
      undefined,
      Number.parseInt(id)
    ).then(res => setSkills(res));
    getCategory({ idCat: 0, depth: 'max' }).then(_res => {
      const res = _res as ICategory;
      setDepthCategories(res);
    });
    getResourcesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addElementHandler = () => {
    const item: any = {};
    item.categoryId = id;
    item.trigger = { repeat: false };
    setActions([...actions, item]);
  };

  function fixGoToId(data: IAction): string[] | undefined {
    //se esiste resourceId la inserisco nel campo goToId
    const arGotoId = data.resourceId
      ? [data.resourceId]
      : data.goToId
      ? [data.goToId as string]
      : undefined;
    return arGotoId;
  }

  function fixDataLoad(data: IAction): IAction {
    //fixo i campi goToId e goToCateogryId e subItemId
    let fixedDataLoad = data;

    const goToCategoryId = data.goToCategoryId && `${data?.goToCategoryId[0]}`;
    const subItemId = data.goToCategoryId
      ? data.goToCategoryId.length > 1
        ? `${data.goToCategoryId[1]}`
        : undefined
      : undefined;
    const goToId = data.goToId ? data.goToId[0] : undefined;
    let category;
    if (isDocOrVideoOrCourseOrResources(data.goTo)) {
      category = createSubCategory(data.goToCategoryId as number[]);
      data = data.goToId ? { ...data, resourceId: data.goToId[0] } : data;
    }

    if (data.conditions) {
      const fixedConditions = data.conditions.map(condition => {
        const fixedAnswers = JSON.stringify(condition.answers);
        return { ...condition, answers: fixedAnswers };
      });
      fixedDataLoad = {
        ...data,
        goToCategoryId,
        subItemId,
        goToId,
        conditions: fixedConditions,
        category,
      };
    } else {
      fixedDataLoad = { ...data, goToCategoryId, subItemId, goToId, category };
    }
    return fixedDataLoad;
  }
  function createSubCategory(goToCategoryArray: number[]) {
    const subCatObject: SubCategoryType[] = goToCategoryArray.map(item => {
      return { id: item };
    });

    return makeNestedObject(subCatObject);
  }

  function makeNestedObject(arr: SubCategoryType[]) {
    let nested: SubCategoryType = { id: 0 };
    for (let i = arr.length - 1; i >= 0; i--) {
      if (i === arr.length - 1) {
        //assegno il valore
        nested = { id: arr[i].id };
      } else {
        //assegno il valore precedente
        nested = { id: arr[i].id, subCategory: nested };
      }
    }
    return nested;
  }

  function fixDataForSubmit(data: IAction): Partial<IAction> {
    const fixedTrigger = (data.trigger as ITrigger[]).map(item => {
      return item;
    })[0];

    //GOTOCATEGORY
    const actualGoToCategoryIdArray: number[] = [
      Number.parseInt(data.goToCategoryId as string),
    ];
    //aggiungo subItemId se esiste all'array goToCategoryId
    //solo nel caso delle risorse
    if (isDocOrVideoOrCourseOrResources(data.goTo)) {
      data.subItemId && actualGoToCategoryIdArray.push(Number(data.subItemId));
    }

    const goToCategoryId = fixedArrayGoToCategoryId(
      actualGoToCategoryIdArray,
      data.category?.subCategory
    );
    //GOTOID
    const goToId = fixGoToId(data);

    const fixedData = {
      ...omit(data, ['goToCategoryId', 'subItemId', 'resourceId']),
      trigger: fixedTrigger,
      goToCategoryId,
      goToId,
    };
    //CONDITIONS
    if (data.conditions) {
      const fixedConditions = data.conditions.map(condition => {
        let fixedCondition;
        if (isEqual(condition.idQuestion, '-1')) {
          fixedCondition = omit(condition, ['idQuestion']);
        } else {
          fixedCondition = condition;
        }
        if (
          condition.answers &&
          condition.answers !== '-1' &&
          condition.answers !== '[]' &&
          condition.answers.length > 0
        ) {
          const fixedAnswers: number[] = JSON.parse(`${condition.answers}`).map(
            (answer: string) => Number.parseInt(answer)
          );
          return { ...fixedCondition, answers: fixedAnswers };
        } else {
          return omit(fixedCondition, ['answers']);
        }
      });
      return { ...omit(fixedData, ['category']), conditions: fixedConditions };
    } else {
      return omit(fixedData, ['category']);
    }
  }

  function fixedArrayGoToCategoryId(
    goTocategoryIdArray: number[],
    father?: SubCategoryType
  ): number[] {
    if (father) {
      const newArray = father.id
        ? [...goTocategoryIdArray, father.id]
        : goTocategoryIdArray;
      const children = father.subCategory;
      goTocategoryIdArray = fixedArrayGoToCategoryId(newArray, children);
    }

    return goTocategoryIdArray;
  }

  function getActions() {
    retriveAllActions(Number.parseInt(id)).then(res => setActions(res));
  }

  function getParams() {
    retriveAllParams().then(res =>
      setParams({ trigger: res.trigger, event: res.event, goTo: res.goTo })
    );
  }

  function getResourcesData() {
    getAllHyperlinks().then(res => setResourcesData(res));
  }

  function saveAction(action: IAction) {
    const fixedAction = fixDataForSubmit(action);

    if (fixedAction._id) {
      putAction(fixedAction, Number.parseInt(id), fixedAction._id)
        .then(res => {
          dispatch(addSuccessMessage({ title: t('SUCCESSMSG.GENERICSAVE') }));
          getActions();
        })
        .catch(err =>
          dispatch(addErrorMessage({ title: t('ERRORSMSG.GENERICSAVE') }))
        );
    } else {
      postAction(fixedAction, Number.parseInt(id))
        .then(res => {
          dispatch(addSuccessMessage({ title: t('SUCCESSMSG.GENERICSAVE') }));
          getActions();
        })
        .catch(err =>
          dispatch(addErrorMessage({ title: t('ERRORSMSG.GENERICSAVE') }))
        );
    }
  }

  function deleteActionHandler(action: IAction) {
    if (action._id) {
      deleteAction(action.categoryId, action._id).then(res => getActions());
    } else {
      setActions(actions.filter(item => item._id !== action._id));
    }
  }

  return (
    <div className="game-settings">
      {actions.map((ele, index) => (
        <ActionForm
          action={fixDataLoad(ele)}
          key={index}
          categories={categories}
          skills={skills}
          goTo={params.goTo}
          triggers={params.trigger}
          events={params.event}
          langId={user?.preference.language || 'it'}
          deleteActionHandler={deleteActionHandler}
          submitActionHandler={saveAction}
          animationGames={animationGames}
          depthCategories={depthCategories}
          resourcesData={resourcesData}
        />
      ))}
      <Row className="justify-content-end">
        <Col xs="auto">
          <Icon
            icon="add"
            label={t('APP.ADD')}
            title={t('APP.ADD')}
            size="20px"
            colorStyle="#01a381"
            clickAction={addElementHandler}
          ></Icon>
        </Col>
      </Row>
    </div>
  );
}

export default Actions;
